define("discourse/plugins/email-to-topic/discourse/initializers/extend-for-email-to-topic", ["exports", "discourse/models/composer"], function (_exports, _composer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'extend-for-email-to-topic',
    initialize: function () {
      _composer.default.serializeOnCreate('email_to_topic');
      _composer.default.serializeToTopic('email2topic', 'topic.email_to_topic');
    }
  };
});